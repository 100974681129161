import unlessElementExists from "./lib/utils/unlessElementExists.js";
import styles from "./config/googleMap.js";
import gdpr from "./config/gdpr.js";
import {fadeHide, fadeShow, fadeToggle} from "./lib/animate/index.js";
import addEventListener from './lib/utils/addEventListener.js'
import aos from 'aos';
import { createApp } from 'vue';
import StraylightRestaurantMenu from "./components/StraylightRestaurantMenu.vue";
import {typhoon, Modal} from "./lib/typhoon/index.js";
import getFieldsValues from "./lib/utils/getFieldsValues.js";
import {useLocalStorage, useSessionStorage} from "./lib/storage/index.js";
import useCookies from "./lib/cookies";

gdpr.apps.googleMap.callback = () => {
    unlessElementExists('.google-map-wrapper', wrapper => {
        const icon = '/dist/img/marker.svg';

        const position = new google.maps.LatLng( 50.463600, 4.862930 );

        const map = new google.maps.Map( wrapper, {
            center: position,
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles,
        } );

        new google.maps.Marker( {
            position,
            map,
            icon,
        } );
    } );
};

const storage = useLocalStorage();
const cookies = useCookies();
const session = useSessionStorage();

unlessElementExists('.app', container => {
    createApp({
        components: {
            StraylightRestaurantMenu
        },
        mounted() {
            aos.init();
            typhoon();

            unlessElementExists('#nav', nav => {
                const checkBreakPoints = function () {
                    if ( window.innerWidth <= 768 ) {
                        nav.style.display = 'none';
                        nav.style.opacity = '0';
                    } else {
                        nav.style.display = 'block';
                        nav.style.opacity = '1';
                    }
                };

                checkBreakPoints();
                window.addEventListener( 'resize', checkBreakPoints );

                unlessElementExists('#openMenu', button => {
                    let nav_state = false;

                    addEventListener( button, {
                        click() {
                            nav_state = !nav_state;

                            fadeToggle( nav, 150 ).then( () => {
                                nav_state ? document.body.classList.add('nav-opened') : document.body.classList.remove('nav-opened');
                            } );
                        }
                    } );
                } );
            } );

            unlessElementExists('#popupModal', popup => {
                const modal = Modal.getInstanceOrCreate( popup );

                modal.options.afterHide = () => {
                    session.set('popup.show', false );
                };

                if ( session.get('popup.show', true ) ) {
                    setTimeout( function () {
                        modal.show();
                    }, 1000 * 5 );
                }
            } );

            unlessElementExists('#cookiesNotice', notice => {
                function handleChoices(choices) {
                    Object.entries( gdpr.categories ).forEach( ([name,values]) => {
                        if ( choices[ name ] === true && values.apps !== null ) {

                            values.apps.forEach( app => {
                                if ( gdpr.apps[ app ] ) {
                                    unlessElementExists( '#' + gdpr.apps[ app ].id, el => {
                                        if ( el.tagName.toLowerCase() === 'script' ) {
                                            if ( el.hasAttribute('data-src') ) {
                                                el.onload = () => typeof gdpr.apps[ app ].callback === 'function' && gdpr.apps[ app ].callback( el );
                                                el.type = el.getAttribute('data-type');
                                                el.src = el.getAttribute('data-src');
                                            } else {
                                                const newScriptTag = document.createElement('script');
                                                newScriptTag.innerHTML = el.innerHTML;
                                                typeof gdpr.apps[ app ].callback === 'function' && gdpr.apps[ app ].callback( newScriptTag );
                                                el.parentNode.insertBefore( newScriptTag, el );
                                                el.parentNode.removeChild( el );
                                            }
                                        }

                                        if ( el.tagName.toLowerCase() === 'iframe' ) {
                                            typeof gdpr.apps[ app ].callback === 'function' && gdpr.apps[ app ].callback( el );
                                            el.src = el.getAttribute('data-src');
                                        }
                                    } );
                                }
                            } );
                        }
                    } );

                    unlessElementExists('#cookiesModal', modal => {
                        unlessElementExists(modal, '#cookiesForm', form => {
                            Object.entries( choices ).forEach( ([name,value]) => {
                                if ( value ) {
                                    form.querySelector('input[type=checkbox][name='+ name +']').setAttribute('checked', true );
                                }
                            } );
                        } );
                    } );
                }

                function saveChoices(choices) {
                    storage.set('cookies.bar', 'set' );
                    cookies.set('cookies_settings', choices, {secure: location.protocol === 'https:' });

                    handleChoices( choices );
                }

                if ( !storage.has('cookies.bar') ) {
                    setTimeout( () => fadeShow( notice, 300 ), 0 );
                }

                const categories = gdpr.categories;

                const default_choices = Object.fromEntries(
                    Object.entries( categories ).map( ([name,values]) => [ name, values.default ] )
                );

                handleChoices(
                    cookies.get('cookies_settings', default_choices )
                );

                notice.querySelector('#cookiesAcceptBtn').addEventListener('click', () => {
                    saveChoices( Object.fromEntries(
                        Object.entries( categories ).map( ([name,values]) => [ name, true ] )
                    ) );

                    fadeHide( notice, 300 );
                } );

                notice.querySelector('#cookiesRefuseBtn').addEventListener('click', () => {
                    saveChoices( default_choices );

                    fadeHide( notice, 300 );
                } );

                unlessElementExists('#cookiesModal', modal => {
                    unlessElementExists( modal, '#cookiesForm', form => {
                        form.addEventListener('submit', e => {
                            e.preventDefault();

                            saveChoices( getFieldsValues( form ) );

                            fadeHide( notice, 300 );
                            Modal.getInstanceOrCreate( modal ).hide();
                        } );
                    } );
                } );
            } );
        }
    }).mount( container );
} );