class Storage
{
    #storage;

    /**
     *
     * @param storage_type
     */
    constructor(storage_type = 'session')
    {
        this.#storage = storage_type === 'session' ? window.sessionStorage : window.localStorage;
    }

    /**
     * Check if an item exists for the given key
     *
     * @param key
     * @returns {boolean}
     */
    has(key)
    {
        return this.#storage.getItem( key ) !== null
    }

    /**
     * Forget an item for the given key
     *
     * @param key
     */
    forget(key)
    {
        this.#storage.removeItem( key );
    }

    /**
     * Get the item for the given key or return the default value
     *
     * @param key
     * @param default_value
     * @returns {null|any}
     */
    get(key, default_value = null)
    {
        const value = this.#decode( this.#storage.getItem( key ) );

        if ( value !== null ) {
            return value;
        }

        return default_value;
    }

    /**
     * Set an item with the given key
     *
     * @param key
     * @param value
     */
    set(key, value)
    {
        this.#storage.setItem( key, this.#encode( value ) );
    }

    /**
     * Decode value from encoded storage.
     * @param value
     * @return {RegExp|Date|boolean|string|*|number}
     */
    #decode(value)
    {
        let type, length, source;

        length = value?.length || 0;

        if ( length < 10 ) {
            return null;
        }

        type = value.substring( 0, 8 );
        source = value.substring( 9 );

        switch ( type ) {
            case '__date__':
                return new Date( source );

            case '__expr__':
                return new RegExp( source );

            case '__numb__':
                return Number( source );

            case '__bool__':
                return Boolean( source === '1' );

            case '__strn__':
                return `${source}`;

            case '__objt__':
                return JSON.parse( source );

            default:
                return value;
        }
    }

    /**
     * Encode value for storage.
     * @param value
     * @return {string|*}
     */
    #encode(value)
    {
        if ( Object.prototype.toString.call( value ) === '[object Date]' ) {
            return `__date__|${value.toUTCString()}`;
        }

        if ( Object.prototype.toString.call( value ) === '[object RegExp]') {
            return `__expr__|${value.source}`;
        }

        if ( typeof value === 'number') {
            return `__numb__|${value}`;
        }

        if ( typeof value === 'boolean') {
            return `__bool__|${value ? '1' : '0'}`;
        }

        if ( typeof value === 'string') {
            return `__strn__|${value}`;
        }

        if ( typeof value === 'function') {
            return `__strn__|${value.toString()}`;
        }

        if ( value === Object( value ) ) {
            return `__objt__|${JSON.stringify( value )}`;
        }

        return value;
    }
}

const sessionStorage = new Storage('session');
const localStorage = new Storage('local');

export function useSessionStorage()
{
    return sessionStorage;
}

export function useLocalStorage()
{
    return localStorage;
}
